import toggleLocationField from './choices/locations';
import toggleCustomButtonPositionsDetails from './choices/custom_button_positions';

export default function setupChoicePropertyFieldsAndDetails() {
  $(document).on('change', '.js-prompt-style-field', (e) => {
    const target = e.target;

    toggleLocationField(target);
    toggleCustomButtonPositionsDetails(target);
  });
}
