import {
  configureContentUrl,
  loadModalContent
} from '../../lib/load_modal_content';

import {
  SELECTOR_HIGHLIGHT_ISLAND,
  SELECTOR_HIGHLIGHTED_ISLAND,
  highlightView
} from './highlightIsland';

const SELECTOR_TOGGLE = '.js-drawer__toggle';
const SELECTOR_OPEN = '.js-drawer__open';
export const SELECTOR_LOAD = '.js-drawer__load';
export const SELECTOR = '.js-drawer';
const SELECTOR_HIGHLIGHTED = '.wrp-highlighted';

// Used to determine the state of the drawer.
// Drawer loaded is currently used to leave toggle button highlighted in certain contexts.
const CLASS_OPEN = 'wrp-studio--open';
const CLASS_LOAD = 'wrp-studio--drawer-loaded';

// Events to coordinate with the other parts of the code
export const EVENT_WILL_OPEN = 'drawer:will-open';
export const EVENT_WILL_CLOSE = 'drawer:will-close';
export const EVENT_DID_OPEN = 'drawer:did-open';
export const EVENT_DID_CLOSE = 'drawer:did-close';
export const EVENT_CANCEL_CLOSE = 'drawer:cancel-close';

const KEYCODE_ESCAPE = 27;

/**
 * Controls the opening and closing of the drawer, either by:
 *  - clicks on a toggle that will open/close according to the current state
 *  - click on an "open" button (for ex. over each island)
 *  - hitting the "Escape" key to close it
 */
export default function setupDrawer() {
  $(document).on('click', SELECTOR_TOGGLE, function(event) {
    if (!event.isDefaultPrevented()) {
      event.preventDefault();
      if ($(document.body).hasClass(CLASS_OPEN)) {
        closeDrawer();
      } else {
        populateDrawer({ toggle: event.currentTarget });
        openDrawer();
      }
    }
  });

  $(document).on('click', SELECTOR_OPEN, function(event) {
    if (!event.isDefaultPrevented()) {
      event.preventDefault();

      if ($(document.body).hasClass(CLASS_OPEN)) {
        const close_confirmed = confirm(
          'Are you sure? Unsaved changes will be lost.'
        );
        close_confirmed ? openDrawer() : cancelClosingDrawer();
      } else {
        openDrawer();
      }
    }
  });

  // JointJS prevents clicks when a `touchend` event happens
  // so we need to hook on the `studio:cell:pointerclick` event
  // to trigger the drawer opening. However on devices where the `click`
  // is left alone, this would trigger the handler twice so we need
  // a little scheduling code to ensure the handler only runs once.
  // Thanks to events being synchronous, we can just set a flag to
  // check if it already happend during the current frame.
  let triggered;
  $(document).on('click studio:cell:pointerclick', SELECTOR_LOAD, function(
    event
  ) {
    if (!triggered) {
      triggered = true;
      requestAnimationFrame(() => (triggered = false));

      if (
        !event.isDefaultPrevented() &&
        !event.target.closest(SELECTOR_HIGHLIGHTED)
      ) {
        event.preventDefault();
        populateDrawer({ toggle: event.currentTarget });
      }
    }
  });

  $(document).on('keydown', event => {
    if (event.keyCode == KEYCODE_ESCAPE) {
      closeDrawer();
    }
  });

  // When all islands become unselected an event will be issued closing the drawer and clearing selections.
  $(document).on('studio:unselect-all', '.paper-scroller-background', () => {
    closeDrawer();
  });
}

/**
 * Opens form drawer and toggles the appropriate ARIA attributes
 * on the toggle.
 */
export function openDrawer() {
  const toggle = document.querySelector(SELECTOR_TOGGLE);
  const drawer = document.querySelector(SELECTOR);
  toggle.setAttribute('aria-expanded', 'true');
  $(document.body).addClass(CLASS_OPEN);
  const event = new CustomEvent(EVENT_DID_OPEN, { bubbles: true });
  drawer.dispatchEvent(event);
}

/**
 * Closes form drawer.
 */
export function closeDrawer() {
  const toggle = document.querySelector(SELECTOR_TOGGLE);
  const drawer = document.querySelector(SELECTOR);
  const event = new CustomEvent(EVENT_WILL_CLOSE, {
    bubbles: true,
    cancelable: true
  });
  drawer.dispatchEvent(event);
  if (!event.defaultPrevented) {
    toggle.setAttribute('aria-expanded', 'false');
    $(document.body).removeClass(CLASS_OPEN);
    const event = new CustomEvent(EVENT_DID_CLOSE, { bubbles: true });
    drawer.dispatchEvent(event);
  }
}

export function cancelClosingDrawer() {
  const drawer = document.querySelector(SELECTOR);
  true;
  const event = new CustomEvent(EVENT_CANCEL_CLOSE, {
    detail: true,
    bubbles: true
  });
  drawer.dispatchEvent(event);
}

/**
 * Adds a class to the body of the studio markup to determine whether the drawer is populated.
 * Used to keep toggle button highlighted when the drawer is populated, even when closed.
 * @param {Boolean} remove Whether to remove or add the class.
 */
const updateDrawerLoadedClass = (remove = false) => {
  if (remove) {
    document.body.classList.remove(CLASS_LOAD);
  } else {
    document.body.classList.add(CLASS_LOAD);
  }
};

/**
 * Responsible for loading the contents of the drawer, requires url to populate form.
 * @param {HTMLElement} toggle Element interacted with which triggers further logic.
 * @param {HTMLElement} drawer Element of the drawer itself, required for content to injected to it.
 * @param {String} url Used to request form markup and data.
 * @param {Boolean} triggeredFromLink Used to define if the request was triggered from a link (e.g Add island)
 */
export function populateDrawer({
  toggle = document.querySelector(SELECTOR_TOGGLE),
  drawer = document.querySelector(SELECTOR),
  url = toggle.getAttribute('data-modal-content-url') || toggle.href,
  triggeredFromLink = false,
  withLoadingModal = true
} = {}) {
  const highligthSelectors = [
    SELECTOR_HIGHLIGHT_ISLAND.slice(1, SELECTOR_HIGHLIGHT_ISLAND.length),
    SELECTOR_HIGHLIGHTED_ISLAND.slice(1, SELECTOR_HIGHLIGHTED_ISLAND.length)
  ];

  const isHighlighted =
    toggle.classList.contains(highligthSelectors[0]) &&
    toggle.classList.contains(highligthSelectors[1]);
  const anyHighlighted = document.querySelectorAll(
    `${SELECTOR_HIGHLIGHTED_ISLAND}`
  ).length;
  const isToggleButton = toggle.classList.contains(
    SELECTOR_TOGGLE.slice(1, SELECTOR_TOGGLE.length)
  );

  // Clears the drawer loaded class if present.
  updateDrawerLoadedClass(true);

  // The following conditions were separated into their own if statements for readability.

  // Used to load form content for new island.
  if (!anyHighlighted && isToggleButton) {
    configureContentUrl(drawer, url);
    loadModalContent(drawer, withLoadingModal);
    updateDrawerLoadedClass();
  }

  // An island that is not highlighted will have its contents loaded.
  // Runs when an island is clicked.
  if (!isHighlighted && !isToggleButton) {
    configureContentUrl(drawer, url);
    loadModalContent(drawer, withLoadingModal);
    updateDrawerLoadedClass();
  }

  // An island that has been created from the navbar.
  if (triggeredFromLink && isToggleButton) {
    configureContentUrl(drawer, url);
    loadModalContent(drawer, withLoadingModal);
    updateDrawerLoadedClass();
  }
}

/**
 * Opens and selects starting island when a fresh project is created.
 * @param {Object} options.graph JointJS graph. Required to find number of islands in the project.
 * @param {Object} options.paper JointJS paper. Required to find island view for highlighting
 */
export function openDrawerOnNewProject({ paper, graph }) {
  // Search for search params to check drawer should be open.
  const urlParams = new URLSearchParams(window.location.search);
  const shouldDrawerOpen = urlParams.get('drawer') == 'open';
  const islands = graph.getElements();

  setTimeout(() => {
    // There should be a single island at the creation of the project.
    if (shouldDrawerOpen && islands.length === 1) {
      const onlyIsland = islands[0];
      const view = paper.findViewByModel(islands[0]);
      highlightView(view);
      // highlightArea.classList.add(highlightClass);

      openDrawer();

      populateDrawer({
        toggle: view.el,
        url: onlyIsland.attributes.links.edit
      });
    }
  });
}
